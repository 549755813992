import { useAuthStore } from '~/stores/auth'
import { useAlertStore } from '~/stores/alert'
const { me } = useAuth()

export default defineNuxtRouteMiddleware(async (_to, _from) => {
  await me()
  const { user, isAdmin, isJudge, isSponsor, isLeader, isUser, defaultUserPage } = useAuthStore()
  const alertStore = useAlertStore()
  // console.log(isSponsor)
  if (await isAdmin || await isLeader || await isUser || await isJudge)
    return true

  if (!await isSponsor) {
    alertStore.setAlert('You were redirected, because you are not authorized to view this page', 'error')
    return navigateTo(defaultUserPage)
  }

  if (user?.yearsInvited) {
    console.log('here we are')
    if (!user.yearsInvited[0].accepted) {
      console.log('we have not accepted')
      if (user.yearsInvited[0].userSubmittedAt)
        return navigateTo('/')
      return navigateTo('/welcome/back')
    }
    console.log('hererererere')
    if (!user?.carts?.length) {
      // console.log('Sponsor: No carts')
      if (!user?.tradeProfile.length) {
        console.log('no trade profile either')
        return navigateTo('/welcome')
      }
      else {
        console.log('hererererere2')
        if (!user?.tradeProfile[0].Contribution?.length) {
          console.log('no trade profile contributions')
          return navigateTo('/welcome')
        }
        return true
      }
    }
    if (!user?.carts?.[0]?.Purchase && !user?.tradeProfile.length)
      return navigateTo('/welcome')
  }
  navigateTo('/')
})
